/*
 * Styling
 *
 * @author Ahmed Samir Morsy
 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.spanText {
  color: white;
}
/* Menu */
.menu .actions {
  color: #000000;
  display: flex;
}
.menu .actions a {
  color: #000000;
  margin-left: 16px;
} /* table */
/* .tableComponent thead,
.tableComponent tbody {
  display: block;
} */
/* .tableSingleComponent thead,
  .tableSingleComponent tbody {
    display: block;
  } */
.tableComponent {
  /* overflow: auto; */
  width: 100%;
} /* globals */
.hyperLink {
  color: #1f4696;
  cursor: pointer;
}
.spread {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.fullWidth {
  width: 100%;
}
.center {
  text-align: center;
  display: flex;
  justify-content: center;
}
.customPagination {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customPagination .MuiPagination-ul {
  justify-content: center;
}
.btnActions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
  /* width: 100%; */
  margin-left: auto;
}
.btnActions button {
  margin-left: 10px;
  margin-bottom: 10px;
}
.formBtn {
  margin-bottom: 5px;
  margin-top: 10px;
}
.formBtn button {
  padding: 8px 15px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: firebrick;
  color: #fff;
}
.indent {
  padding-left: 20px;
  box-sizing: border-box;
}
.imagesHolder {
  display: flex;
  flex-wrap: wrap;
}
.imagesHolder img {
  width: 150px;
}
.imageHolder {
  display: flex;
  flex-direction: column;
  margin: 5px 20px 5px 0;
}
.imageHolder button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: firebrick;
}
.chip {
  margin: 2px 1px 2px 0;
}
.submitHolder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
}
.submitHolder button {
  background-color: #1f4696;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
/* end global */ /* Single page design */
.singleComponent {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  /* max-width: 1200px; */
  margin: auto;
}
.singleComponent h2 {
  text-transform: capitalize;
  position: relative;
  width: fit-content;
  font-size: 25px;
  font-weight: bold;
  color: white;
}
.singleComponent h2::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 70%;
  height: 2px;
  background-color: white;
}
.singleComponent .singleObj {
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  min-width: 24%;
}
.singleComponent .MuiPaper-rounded {
  border-radius: 10px;
}
.singleComponent .singleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.media {
  width: 27%;
  height: 250px;
  margin: 25px 0;
}
@media (max-width: 1367px) {
  .media {
    width: 44%;
  }
}
@media (max-width: 767px) {
  .media {
    width: 100%;
  }
}
.singleComponent .singleContainer h2 {
  font-size: 23px;
}
.singleComponent .singleContainerNoColor {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background-color: transparent;
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  /* border: 2px solid rgba(0,0,0,0.1); */
}
.singleComponent .singleData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 24%;
  margin: 5px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
} /* .singleComponent .singleData:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
} */
.singleComponent .singleData p {
  margin: 0;
}
.singleComponent .singleData p strong {
  margin-bottom: 5px;
  text-transform: capitalize;
}
.singleComponent .singleData img {
  width: 200px;
} /* dialog */
.dialog .singleComponent .singleData {
  min-width: 100%;
  text-align: center;
} /* menu */
.activeMenu {
  border-right: 2px solid #1f4696 !important;
}
.activeMenu svg,
.activeMenu span {
  color: #1f4696;
} /* table design */
tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
tr:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.tableComponent .MuiToolbar-regular {
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}
thead,
thead tr,
thead tr th,
.tableComponent .MuiToolbar-regular,
.tableEmpty .MuiToolbar-regular {
  background-color: #1f4696 !important;
  color: #fff !important;
}
.tableEmpty {
  width: 100%;
}
.tableComponent .MTableToolbar-searchField-24,
.tableEmpty .MTableToolbar-searchField-24,
.tableComponent .MTableToolbar-searchField-24 span,
.tableEmpty .MTableToolbar-searchField-24 span,
.tableComponent .MTableToolbar-searchField-24 input,
.tableEmpty .MTableToolbar-searchField-24 input,
.tableComponent .MTableToolbar-searchField-24 .MuiInputBase-root,
.tableEmpty .MTableToolbar-searchField-24 .MuiInputBase-root {
  color: #fff !important;
}
.tableComponent .MTableToolbar-searchField-24 .MuiInputBase-root::before,
.tableEmpty .MTableToolbar-searchField-24 .MuiInputBase-root::before,
.tableComponent .MTableToolbar-searchField-24 .MuiInputBase-root:hover,
.tableEmpty .MTableToolbar-searchField-24 .MuiInputBase-root:hover {
  border-bottom: 1px solid #fff;
}
.tableEmpty .MuiInput-underline:hover:not(.Mui-disabled):before,
.tableComponent .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #fff;
} /* filters */
.filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.filter {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}
.filters button {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 12px 10px;
  border-radius: 15px;
  border: none;
  background-color: #1f4696;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.filters button:hover {
  opacity: 0.7;
} /* responsive */
@media only screen and (min-width: 1400px) {
  .singleComponent .singleData {
    font-size: 16px;
  }
  .singleComponent h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 700px) {
  .singleComponent .singleData {
    min-width: 100%;
  }
  .singleComponent .singleObj {
    min-width: 100%;
  }
  /* .tableComponent thead,
  .tableComponent tbody {
    display: block;
  } */
  .filter {
    width: 100%;
  }
}
.formControl {
  margin: 8px;
  min-width: max-content;
  width: 250px;
}
.selectEmpty {
  margin-top: 16px;
}
.collectionActions {
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  width: 100%;
  flex-wrap: wrap;
}
